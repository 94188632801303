@font-face {
    font-family: "MontserratRegular";
    src: url('../font/Montserrat/Montserrat-Medium.ttf');
}
@font-face {
    font-family: "MontserratThin";
    src: url('../font/Montserrat/Montserrat-Thin.ttf');
}

body {
    font-family: Helvetica
}

.linkMenu {
    font-size: 18px;
    font-weight: normal;
    font-family: Helvetica;
    color: #000;
}

.linkMenu:hover {
    color: #5340ff;
    text-decoration: none;
    outline: none !important;
    transition: .3s ease;
}

ul.linkMenu {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

li.linkMenu {
    list-style: none;
    padding: 0 10px 0 10px;
    display: inline-block;
    float: left;
}

ul.slide-menu {
    list-style: none;
    padding: 30px 0 0 0;
}

li.slide-menu {
    list-style: none;
    margin: 20px 0 20px 0;
}
.slide-text-color {
    color: #aaaaaa;
}
.slide-text-color:hover {
    color: #5340ff;
}
.slide-text-color:active {
    color: #7643fc;
}


.loginInputLabel {
    margin-bottom: 0;
}

.loginInputDiv {
    margin-bottom: 20px;
}

.social-media-link a {
    color: #000659
}
.social-media-link a:hover {
    color: #6a03fd
}

.justify-content-center {
    display: flex;
    justify-content: center;
}
.justify-content-left {
    display: flex;
    justify-content: left;
}


.font-size-12 {
    font-size: 12px;
}
.font-size-14 {
    font-size: 14px;
}
.font-size-20 {
    font-size: 20px;
}

.box-shadow {
    box-shadow: 0 0 10px gray;
}

.languageSelected {
    background: #fff linear-gradient(180deg, #e1e0ff 0%, #fff 70%);
    color: rgb(0, 6, 89);
    transition: .3s ease;
    width: 151px;
    font-family: "GothamGotham", sans-serif;
}

.languageSelected:hover {
    background: #fff linear-gradient(180deg, #e1e0ff 0%, #fff 70%);
    color: rgb(0, 6, 89);
    transition: .3s ease;
    width: 151px;
    font-family: "GothamGotham", sans-serif;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-20 {
    margin-top: 20px;
}
.vertical-align-text-bottom{
    vertical-align: text-bottom;
}
.no-padding {
    padding: 0 0 0 0;
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}

.bg-white {
    background-color: white;
}

.p-font-color {
    color: #5340FF
}
.p-font-bold {
    font-family: Helvetica-Bold;
}

.promodex-addmore-btn {
    color: #c857e7;
    border-color: #c857e7;
    border-radius: 30px;
    padding: 0 8px;
}
.promodex-addmore-btn:focus {
    color: #c857e7;
    border-color: #c857e7;
}
/* -----> ANT DESIGN CSS START <----- */
.ant-table-small .ant-table-thead > tr > th {
    background-color: #ffffff;
}

.ant-table-thead > tr > th {
    background-color: #ffffff;
    font-weight: 700;
}

/* Clear the border from ant design table*/
/*
* Ant design içinde bulunan tablo, tablo pagination ve selectbox için
* ant design içerisinden cssler ezilerek düzeltimiştir.
*/
.ant-table-tbody > tr > td {
    border-bottom: 0 solid #f0f0f0;
    padding: 8px 8px;
}

.activity-table-col .ant-table-tbody > tr > td {
    box-shadow: 0 4px 2px -2px #ececec;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #7651fc;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    border-color: #7651fc;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
    border-color: #7651fc;
    box-shadow: 0 0 0 0 #ffffff;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #7651fc;
    box-shadow: 0 0 0 0 #ffffff;
}

.ant-pagination-item-active {
    border-color: #d9d9d9;
}
.ant-pagination-item-active:hover {
    border-color: #7651fc;
}
.ant-pagination-item-active a {
    color: #7651fc;
}
.ant-pagination-item-active a:hover {
    color: #7651fc;
}
.ant-pagination a:hover {
    color: #7651fc;
}

.select-with-box .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #7651fc;
    border: 1px solid #d9d9d9;
    border-radius: 0 10px 10px 0;
}

/* BÜTÜN İNPUTLARIN BORDERLARINI PROJE RENGINE DONUSTURME */
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #5354df;
}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: #5354df;
    box-shadow: 0 0 0 0 #ffffff;
}

.input-border-style {
    border-radius: 6px;
}
.input-border-style:hover {
    border-color: #5354df;
}
.input-border-style:active {
    border-color: #5354df;
}
.input-border-style:focus {
    border-color: #5354df;
    box-shadow: 0 0 10px #9285fc;
}
/* ANT DESIGN TAB STYLE*/
/* .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: #ffffff;
    background: #6236ff;
}
.ant-tabs-tab:hover {
    color: #6236ff;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 0 solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
} */

.select-after-borderless .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: 0;
}


textarea.ant-input:hover{
    border-color: #6236ff;
}
textarea.ant-input:focus{
    box-shadow: 0 0 0 0 #ffffff;
}

.ant-btn:hover {
    border-color: #6236ff;
    color: #6236ff;
}

.ant-btn:active {
    box-shadow: 0 0 0 0 #ffffff;
    border-color: #6236ff;
}
.ant-btn:hover, .ant-btn:focus {
    border-color: #6236ff;
    color: #6236ff;
}

.checkbox-group-vertical .ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
}

/* -----> ANT DESIGN CSS END <----- */

.shadow-white-div-margin-10 {
    border-radius: 20px;
    padding: 16px 16px;
    background-color: #ffffff;
    box-shadow: 0 0 20px lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.shadow-white-div {
    border-radius: 20px;
    padding: 16px 16px;
    background-color: #ffffff;
    box-shadow: 0 0 20px lightgray;
    /* margin-top: 20px;
    margin-bottom: 20px; */
}

.shadow-grey-div {
    border-radius: 20px;
    padding: 16px 16px;
    background-color: #efefef;
    box-shadow: 0 0 20px lightgray;
    margin-top: 20px;
    margin-bottom: 20px;
}


.connect-wallet-btn {
    color: #5340ff;
    border-color: #5340ff;
    border-radius: 20px;
    padding: 0 20px;
    font-size: 14px;
}
.connect-wallet-btn:hover {
    box-shadow: 0 0 10px #9285fc;
    border-color: #9285fc;
}

.connect-wallet-btn:focus {
    color: #6c54fc;
    border-color: #6c54fc;
}

.sm-btn {
    font-size: 10px;
    padding: 0px 4px;
    border-radius: 8px;
    font-weight: 100;
}

.pr-16 {
    padding-right: 16px ;
}

.promo-btn {
    border-radius: 20px;
    box-shadow: 0 0 10px gray;
    color: #5354df;
}

.promo-btn:hover {
    color: #5354df;
    border-color: #5354df;
}
.promo-btn:focus {
    color: #5354df;
    border-color: #5354df;
}

.h3-padding-0 h3 {
    padding:0;
    margin:0
}

.profile-promo-btn {
    color: #6b03fe;
    cursor: pointer;
    text-decoration: underline;
    -webkit-transition: text-shadow .6s;
}

.profile-promo-btn:hover {
    text-shadow: 0 0.03em 0 #5340ff;
    transition: text-shadow .6s;
}

.settings-promo-btn {
    border-radius: 8px;
    box-shadow: 0 0 10px gray;
    color: #5354df;
    background-color: #efefef;
}

.settings-promo-btn:hover {
    color: #5354df;
    border-color: #5354df;
}
.settings-promo-btn:focus {
    color: #5354df;
    border-color: #5354df;
}

.profile-promoInput-style .ant-input-affix-wrapper {
    border-radius: 10px;
}

.profile-input-label {
    color: #c6c8d4;
    margin-left: 8px;
}

.phone-input {
    border-radius: 10px !important;
    width: 110% !important;
}
.phone-input:disabled{
    background-color: #f5f5f5 !important;
}
.phone-input-flag{
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.yellow-div {
    background-color: #f2ecba;
    border: 1px solid #979797;
    border-radius: 8px;
    padding: 8px 8px;
}

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.ant-switch-checked {
    background-color: #5cc887;
}

.font-white {
    color: #ffffff;
}

.steps-div {
    border-radius: 2px;
    padding: 16px 16px;
    background-color: #ffffff;
    cursor: pointer;
    box-shadow: 0 0 10px black;
    transition: box-shadow 0.4s ease-in-out;
}
.steps-div:hover {
    box-shadow: 0 0 16px #6236ff;
    transition: box-shadow 0.4s ease-in-out;
}

.label-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0;
    border: 0;
    background-color: #6236ff;
    color: white;
}

.select-style{
    width: 100%;
}

ul.no-bullets {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.add-new-task {
    cursor: pointer;
}
.add-new-task:hover {
    color: #5340ff;
    transition: color 0.5s;
}

.text-underline {
    text-decoration: underline;
}

.clickable-text {
    cursor: pointer;
}
.clickable-text:hover{
    text-shadow: 0 0.03em 0 #5340ff;
    transition: text-shadow .6s;
}

.buy-promo-token-div {
    cursor: pointer;
}
.buy-promo-token-div:hover {
    box-shadow: 0 0 10px gray;
    transition: box-shadow 0.6s;
}
.buy-promo-token-div:focus {
    box-shadow: 0 0 10px transparent;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.filter-selectbox .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    font-size: 14px;
    color: #000659;
    width: 100%;
    min-width: 100px;
    border: 0 solid transparent !important;
}

.promodex-grey-title {
    color:#4c4c66;
    font-weight: 700;
}

.slider-arrow {
    cursor: pointer;
    -webkit-transition: box-shadow .6s;
}

.slider-arrow:hover {
    box-shadow: 0 0 10px gray;
    transition: box-shadow .6s;
}

/** Customise the Card body inside dashboardCard component */
.dashboard-card .ant-card-body {
    padding:4px
}

/** Ant design Form uyarı mesajlarını manipule etmek için kullanılan classlar */ 
.ant-form-item-explain, .ant-form-item-extra {
    font-size: 12px;
}

/* Custom colorful button */
.colorful-button {
    transition: all .4s;
    background-image: linear-gradient(to right, #5340ff 0, #ff005e 51%, #5340ff 100%) !important;
    color: rgb(255, 255, 255);
    background-size: 200% auto;
    font-size: 14px;
    border-radius: 20px;
    padding: 10px 30px;
    border: 0px solid transparent !important
}
.colorful-button:hover {
    transition: all .4s;
    background-position: right center !important;
    background-size: 200% auto;
    box-shadow: 0 0 10px #9285fc;
    color: #FFFFFF
}
.colorful-button:active {
    transition: all .4s;
    box-shadow: 0 0 0px #9285fc;
    color: #FFFFFF !important
}


.colorful-button:focus {
    color: #FFFFFF 
}

/* Custom colorful button Without color */
.colorless-button {
    transition: all .4s;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    background-size: 200% auto;
    border: 1px solid #000;
    font-size: 14px;
    border-radius: 20px;
    padding: 11px 30px;
}
.colorless-button:hover {
    transition: all .4s;
    background-position: right center;
    box-shadow: 0 0 10px #9285fc;
    color: rgb(0, 0, 0);
}
.colorless-button:active {
    transition: all .4s;
    box-shadow: 0 0 0px #9285fc;
    color: rgb(0, 0, 0);
}
.colorless-button:focus {
    color: rgb(0, 0, 0);
}

.nowrap {
    white-space: nowrap;
}

/* Make selectbox borderless */
.borderless-selectbox  .ant-select-selector {
    border: 0px solid transparent !important;
    color: #000 !important;
    font-weight: 100 !important;
}

/* Make selectbox smaller */
.sm-selectbox {
    font-size: 11px !important;
}
.sm-seletbox-dropdown .ant-select-item-option-content {
    font-size: 11px !important;
}
.sm-seletbox-dropdown .ant-select-item {
    min-height: 14px;
    padding: 0%;
}

.circle-language-button {
    border-radius: 50%;
    padding: 0px 8px;
    font-size: 10px;
}
.circle-language-menu{
    background-color: transparent;
}

/* align vertically middle the icons that used inline of text  */
.icon-inline {
    display: inline-block;
    vertical-align: middle;
}

/* Landingpage Carousel title style */
.landing-page-title {
    font-family: Helvetica-Bold; 
    font-size: 45px;
    color: #5340FF;
}

/* Landingpage carousel text style */


@media screen and (min-width: 601px) {
    .landing-page-text {
        font-family: Helvetica;
        font-size: 1.45vw;
        color: #283D5C;
        margin-top: -30px;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 600px) {
    .landing-page-text {
        font-family: Helvetica;
        font-size: 3vw;
        color: #283D5C;
        margin-top: -30px;
        margin-bottom: 30px;
    }
  }

@media screen and (min-width: 601px) {
    .landing-page-list-text {
        font-family: Helvetica;
        font-size: 1.3vw;
        font-weight: 100;
        color: #283D5C;
        white-space: nowrap;
    }
}
@media screen and (max-width: 600px) {
    .landing-page-list-text {
        font-family: Helvetica;
        font-size: 3vw;
        font-weight: 100;
        color: #283D5C;
        white-space: nowrap;
    }
  }

/* Landingpage Carousel button style */
.landing-page-carousel li button {
    width: 16px !important;
    height: 16px !important;
    border-radius: 100% !important;
    background: #283D5C !important;
}
.landing-page-carousel li.slick-active button {
    background: #5340FF !important;
}

.landing-page-carousel {
    bottom: 56px !important;
}

/* Connect wallet modal style */
.connect-wallet-modal .ant-modal-content {
    border: 1px solid rgba(83,64,255,0.50);
    box-shadow: 0 0 25px 25px rgba(83,64,255,0.21);
    border-radius: 20.5px;
    background: #FFFFFF;
}

.connect-wallet-buttons {
    width: 166px;
    height: 121px;
    cursor: pointer;
    -webkit-transition: all .4s;
    border-radius: 8px;
}
.connect-wallet-buttons:hover {
    box-shadow: 0 0 10px 10px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}
.connect-wallet-buttons:active {
    box-shadow: 0 0 0px 0px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}

.connected-wallet {
    width: 229px;
    height: 41px;
    border-radius: 20.5px;
    -webkit-transition: all .4s;
    background-color:transparent;
}

.connected-wallet:hover {
    box-shadow: 0 0 10px 10px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}

.dissconnect-walet-btn {
    width: 124px;
    height: 25px;
    border-radius: 18.5px;
    -webkit-transition: all .4s;
}
.dissconnect-walet-btn:hover {
    box-shadow: 0 0 10px 10px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}
.dissconnect-walet-btn:active {
    box-shadow: 0 0 0px 0px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}
.signOut-btn {
    width: 100px;
    height: 20px;
    border-radius: 18.5px;
    -webkit-transition: all .4s;
}
.signOut-btn:hover {
    box-shadow: 0 0 10px 10px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}
.signOut-btn:active {
    box-shadow: 0 0 0px 0px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}


@media screen and (min-width: 601px) {
    .login-register-tabs .ant-tabs-nav-wrap {
        justify-content: right;
        margin-right: 11px;
    }
}
@media screen and (max-width: 600px) {
    .login-register-tabs .ant-tabs-nav-wrap {
        justify-content: center;
        margin-right: 11px;
    }
  }
.login-register-tabs .ant-tabs-nav {
    margin-bottom: 0px;
}
.login-register-tabs .ant-tabs-nav::before {
    border-bottom: 0px solid transparent ;
}
.login-register-tabs .ant-tabs-nav-list {
    margin-bottom: 0px;
    width: 72.2%;
}
.login-register-tabs .ant-tabs-tab {
    background: #FAFBFE;
    border: 1px solid rgba(83,64,255,0.50);
    border-radius: 14px 14px 0 0;
    width: 100%;
    margin:0%;
    font-family: Helvetica-Bold;
    font-size: 14px;
    justify-content: center;
    color: #5340FF;
}
.login-register-tabs .ant-tabs-tab-active {
    background: #5340FF;
    color: #FAFBFE !important;
}
.login-register-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    background: #5340FF;
    color: #FAFBFE !important;
}

.register-button {
    display: block;
    height: 33px;
    padding-top: 5px;
    border-radius: 21.5px;
    text-align: center;
}
.register-button:hover{
    color: #ffffff;
}

.register-title {
    font-size: 18px;
    color: #283D5C;
    padding-top: 20px;
}

.promo-input-label {
    color: black;
    margin-left: 8px;
}

.investor-title {
    font-family: Helvetica-Bold;
    font-size: 14px;
    color: #000659;
    text-align: left;
}

.marginless-bottom {
    margin-bottom: 0px;
    border-bottom-right-radius: 0%;
}

.hide-summary-btn {
    border-radius: 0 0 14.5px 14.5px;
    width:143;
    height:38;
    cursor: pointer;
    -webkit-transition: all .4s;
}
.hide-summary-btn:hover {
    box-shadow: 0 10px 10px 0px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}
.hide-summary-btn:active {
    box-shadow: 0 0 0px 0px rgba(83,64,255,0.21);
    transition: box-shadow .4s;
}

.summary-collapse {
    max-height: 500px !important;
    overflow: hidden;
    transition: all .5s ease;
}
.summary-close {
    max-height: 0px !important;
    padding: 0px;
}


/* DETAYLI KARTLARIN FİLTRELEME TABLARI STYLE KODLARI */
.detailed-card-tabs .ant-tabs-nav-wrap {
    justify-content: left;
}
.detailed-card-tabs .ant-tabs-nav {
    margin-bottom: 0px;
    border-bottom: 0.5px solid #5340FF !important
}
.detailed-card-tabs .ant-tabs-nav::before {
    border-bottom: 0px solid transparent ;
}
.detailed-card-tabs .ant-tabs-nav-list {
    margin-bottom: 0px;
    /* width: 100%; */
}
.detailed-card-tabs .ant-tabs-tab {
    border: 1px solid #5340FF;
    border-radius: 22px 22px 0 0;
    background: #FAFBFE;
    width: 100%;
    padding: 0px 20px;
    margin:0%;
    font-family: Helvetica-Bold;
    font-size: 14px;
    justify-content: center;
    color: #5340FF;
}
.detailed-card-tabs .ant-tabs-tab-active {
    background: #5340FF;
    color: #FAFBFE !important;
}
.detailed-card-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    background: #5340FF;
    color: #FAFBFE !important;
}

/* Border üzerinde yazı bulunan component style */
.border-text-wrapper {
    border: 1px solid rgba(40,61,92,0.50);
    border-radius: 2px;
    padding: 10px 0px 0px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    font-weight: 100;
    color: #283D5C;
  }
  
  
  .border-text-wrapper .border-text {
    position:absolute;
    margin-top:-20px;
    margin-left: 10px;
    background:#FFFFFF;
    padding: 0px 0px 0px !important;
    border-radius:10px;
    padding:0px 0px;
    font-size: 14px;
    color: rgba(40,61,92,0.50);
  }


  .detailed-card-wrapper {
    border-radius: 20px;
    padding: 0px;
    margin: 16px 16px;
    background-color: #ffffff;
    border: 1px solid lightgray;
    box-shadow: 0 0 20px lightgray;
  }

  .padding-16 {
      padding: 16px 16px;
  }

/* DETAYLI KARTLARIN İÇERİDEKİ TABLARIN STYLE KODLARI */

.detailed-card-inside-tabs .ant-tabs-nav-wrap {
    justify-content: left;
}
.detailed-card-inside-tabs .ant-tabs-nav {
    margin-bottom: 0px;
}
.detailed-card-inside-tabs .ant-tabs-nav::before {
    border-bottom: 0px solid transparent ;
}
.detailed-card-inside-tabs .ant-tabs-nav-list {
    margin-bottom: 0px;
    width: 100%;
}
.detailed-card-inside-tabs .ant-tabs-tab {
    border: 1px solid #5340FF;
    border-radius: 10px 10px 0 0;
    background: #FAFBFE;
    padding: 2px;
    width: 100%;
    margin:0%;
    font-family: Helvetica;
    font-weight: 100;
    justify-content: center;
    font-size: 12px;
    color: #283D5C;
}
.detailed-card-inside-tabs .ant-tabs-tab-active {
    background: #5340FF;
    color: #FAFBFE !important;
}
.detailed-card-inside-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    background: #5340FF;
    color: #FAFBFE !important;
}

/* PROJE SAYFASI TABLARI STYLE KODLARI */

/* Tab boyutunun yanındaki tab ile aynı boyda olmasını sağlayan css */
.project-page-tabs .ant-tabs { 
    background-color: #FFFFFF;
    height: 100%;
    border-radius: 14px 14px 0 0;
}

.project-page-tabs .ant-tabs-nav-wrap {
    justify-content: left;
}
.project-page-tabs .ant-tabs-nav {
    margin-bottom: 0px;
}
.project-page-tabs .ant-tabs-nav::before {
    border-bottom: 0px solid transparent ;
}
.project-page-tabs .ant-tabs-nav-list {
    margin-bottom: 0px;
    width: 100%;
}
.project-page-tabs .ant-tabs-tab {
    border: 1px solid #5340FF;
    border-radius: 14px 14px 0 0;
    background: #FAFBFE;
    width: 100%;
    padding: 0px 10px 0px 10px;
    margin:0%;
    font-size: 14px;
    justify-content: center;
    color: #5340FF;
}
.project-page-tabs .ant-tabs-tab-active {
    background: #5340FF;
    color: #FAFBFE !important;
}
.project-page-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    background: #5340FF;
    color: #FAFBFE !important;
    border-radius: 14px 14px 0 0;
}

.border-bottom {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

/* PROJE SAYFASI ALT TARAFTAKi 3LÜ TABS STYLE KODLARI */
.project-page-tabs-2 .ant-tabs-nav-wrap {
    justify-content: left;
    border-bottom: 0.5px solid #5340FF !important
}
.project-page-tabs-2 .ant-tabs-nav {
    margin-bottom: 0px;
}
.project-page-tabs-2 .ant-tabs-nav::before {
    border-bottom: 0px solid transparent ;
}
.project-page-tabs-2 .ant-tabs-nav-list {
    margin-bottom: 0px;
    width: 40%;
}
.project-page-tabs-2 .ant-tabs-tab {
    border: 1px solid #5340FF;
    border-radius: 14px 14px 0 0;
    background: #FAFBFE;
    width: 100%;
    padding: 0px 10px 0px 10px;
    margin:0%;
    font-size: 14px;
    font-weight: 100;
    justify-content: center;
    color: #5340FF;
}
.project-page-tabs-2 .ant-tabs-tab-active {
    background: #5340FF;
    color: #FAFBFE !important;
}
.project-page-tabs-2 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    background: #5340FF;
    color: #FAFBFE !important;
    border-radius: 14px 14px 0 0;
}

.ordered-list {
    font-weight: 100;
    color: #000659;
    font-size: 14px;
}

.ant-table-strip tr:nth-child(even) { background: rgba(40,61,92,0.07); }

.stake-promo-steps-div {
    border-radius: 4px;
    border: 1px solid rgba(40,61,92,0.50);
}
   

.externalCSSBorderRadius10{
    border-radius: 10px;
    padding: 0px;
}
.input-prefix-sale {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: #5340FF;
    color: #FFFFFF;
    padding: 4px 4px 4px 0px;
}
.input-suffix-sale {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    background-color: rgb( 237,235, 255);
    color: rgb( 137,147, 172);
    padding: 4px 0px 4px 4px;
}


.ant-modal-close-icon {
    font-size: 28;
    position: absolute;
    top: 28%;
    right: 20px;
    color: red;
    -webkit-transition: all .4s;
}
.ant-modal-close-icon:hover {
    color: #5340FF;
    transition: all .4s;
}

.show-sample-div {
    position: absolute;
    top: 1%;
    left: 40%;
    text-align: center;
    font-size: 16px;
    z-index: 20px !important;
    color: #5340ff;
    display: inline-block;
    border: 1px solid #5340ff;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    -webkit-transition: all .4s;
}
.show-active {
    top: 90%;
    transition: all .4s;
}
.show-sample-div:hover {
    box-shadow: 0 0 10px #9285fc;
    transition: all .4s;
}
.show-sample-div:active {
    box-shadow: 0 0 0px transparent;
    transition: all .4s;
}



.show-sample-div-stake {
    position: relative;
    top:9%;
    left: 6%;
    text-align: center;
    font-size: 16px;
    z-index: 20px !important;
    color: #5340ff;
    display: inline-block;
    border: 1px solid #5340ff;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    -webkit-transition: all .4s;
}
.show-active-stake {
    left: 85%;
    transition: all .4s;
}
.show-sample-div-stake:hover {
    box-shadow: 0 0 10px #9285fc;
    transition: all .4s;
}
.show-sample-div-stake:active {
    box-shadow: 0 0 0px transparent;
    transition: all .4s;
}





.ant-modal-content {
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    /* background: red; */
  }
  .ant-modal-header {
    border-radius: 50px;
    
    /* background: red; */
  }
  


.shadow-with-border {
    box-shadow: 0 0 10px #9285fc;
    border: 0.2px solid #9285fc;
}


/* Video play button */
.glightbox_video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }
  
  .outer_circle {
    stroke-width: 3;
    stroke-dasharray: 410; 
     stroke-dashoffset: 0;
    stroke-linecap: square;
    transition: all .4s ease-out;
  }
  
  .glightbox_video:hover .outer_circle {
  stroke-dashoffset:410;
    transition: stroke .7s .4s ease-out, stroke-dashoffset .4s ease-out
  }
  
  .glightbox_video:hover 
  .inner-circle {
    fill: #5340FF;
    transition:fill .4s .3s ease-out;
    
  }
  
  .glightbox_video:hover
  .play{
      fill: white;
    transition:fill .4s .3s ease-out;
  }
  /* Video play button End */


  .ant-progress-bg{
      height: 24px !important;
  }

  .ck-editor__editable
 {
    min-height: 200px !important;
    max-height: 500px !important;
 }